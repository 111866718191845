<template>
  <Table border :columns="columns" :data="data">
    <template slot-scope="{ row }" slot="pic">
      <img :src="row.pic" width="100px" height="100px" />
    </template>
    <template slot-scope="{ row }" slot="action">
      <Button type="primary" size="small" style="margin-right: 5px" @click="show(row)">View</Button>
      <Button type="error" size="small" @click="remove(row)">删除</Button>
    </template>
  </Table>
</template>

<script>
import { GetAllList, Detail, Delete } from '@/api/pms.ts';
export default {
  data() {
    return {
      columns: [
        {
          title: 'pic',
          key: 'pic',
          align: 'center',
          slot: 'pic',
        },
        {
          title: 'title',
          key: 'title',
        },
        {
          title: 'subTitle',
          key: 'subTitle',
        },
        {
          title: 'desc',
          key: 'desc',
        },
        {
          title: 'Action',
          slot: 'action',
          width: 150,
          align: 'center',
        },
      ],
      data: [],
    };
  },
  created() {
    this.getAllList();
  },
  methods: {
    getAllList() {
      GetAllList().then((res) => {
        if (res.success) {
          this.data = res.data;
        }
      });
    },
    show(row) {
      this.$router.push({ path: '/admin/pms/detail', query: { id: row._id } });
    },
    remove(row) {
      Delete(row._id).then((res) => {
        if (res.success) {
          this.$Message.success('操作成功');
        }
      });
    },
  },
};
</script>

<style></style>
