import client from '../utils/request';

export function Create(data: any) {
  return client({
    method: 'post',
    url: '/sysApi/pms/add',
    data,
  });
}

export function Update(data: any) {
  return client({
    method: 'post',
    url: `/sysApi/pms/update/${data?._id}`,
    data,
  });
}

export function Detail(id: string) {
  return client({
    method: 'get',
    url: `/sysApi/pms/detail/${id}`,
  });
}

export function Delete(id: string) {
  return client({
    method: 'get',
    url: `/sysApi/pms/delete/${id}`,
  });
}

export function GetList(params: { keyword?: string; pageNum: number; pageSize: number }) {
  return client({
    method: 'get',
    url: '/sysApi/pms/list',
    params,
  });
}

export function GetAllList() {
  return client({
    method: 'get',
    url: '/sysApi/pms/listAll',
  });
}
